




































import Vue from 'vue';
import { FormType } from '~/pages/login/constant';
import Register from '~/pages/login/component/register.vue';
import Bind from '~/pages/login/component/bind.vue';
import Forget from '~/pages/login/component/forget.vue';
import Login from '~/pages/login/component/login.vue';

export default Vue.extend({
  components: {
    'ju-login': Login,
    'ju-forget': Forget,
    'ju-register': Register,
    'ju-bind': Bind,
  },
  data() {
    return {
      FormType,
      formType: FormType.login,
    };
  },
  mounted() {
    // console.log(this, 'el-uploadel-uploadel-uploadel-upload');
  },
  methods: {
    showInfo() {
      (this.$completeInfo as any).show();
    },
    handleFormTypeChange(type: FormType) {
      this.$data.formType = type;
    },
    closeLogin() {
      this.$login.hide();
    },
  },
});
