

































import Vue from 'vue';

export default Vue.extend({
  name: 'ReservationExh',
  props: {
    data: {
      type: Object,
    },
  },
});
