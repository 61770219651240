
















































































import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import { $cookies } from '~/util/api';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  data() {
    return {
      isLogin: false,
      menu: [
        {
          label: this.$t('menu.home'),
          title: this.$t('common.site_title'),
          path: `${this.$config.HOST_NAME}`,
          activePath: '/',
          extra: true,
        },
        {
          label: this.$t('menu.domestic'),
          title: this.$t('menu.domestic'),
          path: `${this.$config.HOST_NAME}/exhibition-0-0-1-0-0-0-1/`,
          activePath: 'exhibition-(\\d+)-(\\d+)-1',
        },
        {
          label: this.$t('menu.international'),
          title: this.$t('menu.international'),
          path: `${this.$config.HOST_NAME}/exhibition/`,
          activePath: /^\/exhibition((-\d+){2}(-[0,2-9]+)(-\d+){4})?\/?/,
        },
        {
          label: this.$t('menu.exhibitionInfo'),
          title: this.$t('menu.exhibitionInfo'),
          path: `${this.$config.HOST_NAME}/information/`,
          activePath: '/information',
        },
        {
          label: this.$t('menu.conventionCenter'),
          title: this.$t('menu.conventionCenter'),
          path: `${this.$config.HOST_NAME}/pavilion/`,
          activePath: /^\/pavilion((-\d+){2}(-[0,2-9]+)(-\d+){4})?\/?/,
        },
        {
          label: this.$t('menu.cooperate'),
          title: this.$t('menu.cooperate'),
          path: `${this.$config.HOST_NAME}/cooperation.html`,
          activePath: '/cooperation.html',
        },
        {
          label: this.$t('menu.visa'),
          title: this.$t('menu.visa'),
          path: `${this.$config.VISA}/visa-0-0-0-0-1/`,
          activePath: 'visa-(\\d+)-(\\d+)-(\\d+)-(\\d+)-1',
        },
        {
          label: '参展企业',
          title: '参展企业',
          path: `${this.$config.HOST_NAME}/company/`,
          activePath: '/company',
          hidden: true,
        },
      ],
      keyword: '',
      keywordError: false,
      keywordPlaceholder: this.$t('menu.keywordPlaceholder1'),
      credit: 0,
    };
  },
  computed: {
    ...mapState({
      stateIsLogin: (state: any) => state.global.isLogin,
      locale: (state: any) => state.i18n.locale,
    }),
  },
  watch: {
    stateIsLogin(v) {
      this.isLogin = v;
    },
  },
  mounted() {
    this.isLogin = this.stateIsLogin;
    // 如果url中有login参数，则弹出登录
    if (!this.isLogin && this.$route.query.hasOwnProperty('login')) {
      this.handleLogin();
    }
  },
  methods: {
    ...mapMutations({
      setLang: 'i18n/SET_LANG',
    }),
    toPath(url: string) {
      window.open(url, '_blank');
    },
    handleLogin() {
      this.$login.show(this.$i18n);
    },
    async handleLogout() {
      const res: any = await this.$axios.get('/api/auth/logout');
      if (res.code === ResponseCode.SUCCESS) {
        $cookies.removeAll();
        this.$store.commit('global/setIsLogin', false);
        this.isLogin = false;
      }
    },
    handleKeywordInput() {
      const { keyword } = this;
      if (!keyword) {
        this.keywordError = false;
        this.keywordPlaceholder = this.$t('menu.keywordPlaceholder1');
      } else if (keyword.length > 50) {
        this.keywordError = true;
        this.keywordPlaceholder = this.$t('menu.keywordPlaceholder2');
      } else {
        this.keywordError = !/^[a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword);
        this.keywordPlaceholder = this.$t('menu.keywordPlaceholder3');
      }
    },
    handleSearch: _.debounce(
      function () {
        // @ts-ignore
        const { keyword } = this;
        // if (!isLogin) {
        //   $login.show();
        //   return;
        // }

        if (!/^[0-9a-zA-Z\u4E00-\u9FA5 \(\)（）]+$/.test(keyword)) {
          // @ts-ignore
          this.keywordError = true;
          // @ts-ignore
          this.$message.error(this.$t('menu.keyword_error1'));
          return;
        } else {
          // @ts-ignore
          this.keywordError = false;
        }
        if (keyword.length > 50) {
          // @ts-ignore
          this.keywordError = true;
          // @ts-ignore
          this.$message.error(this.$t('menu.keyword_error2'));
          return;
        }
        if (keyword) {
          let url = 'https://www.jufair.com';
          if (window.location.host.includes('visa-dev.'))
            url = 'https://vue.jufair.com';
          if (window.location.host.includes('visa.'))
            url = 'https://www.jufair.com';

          window.open(`${url}/exhibition/?keyword=${keyword}`, '_blank');
        }
      },
      500,
      { leading: true }
    ),
    handleSetLang() {
      const uri = new URLSearchParams(location.search);
      const lang = this.locale === 'en' ? 'zh' : 'en';
      this.setLang(lang);
      uri.set('lang', lang);

      document.location.search = uri.toString();
    },
  },
});
