var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news_bottom"},[_c('div',{staticClass:"title_more"},[_c('section-title',{staticStyle:{"font-size":"20px","font-weight":"bold"},attrs:{"title":((_vm.$get(_vm.industry, '[0].name', '')) + "行业动态资讯")}}),_vm._v(" "),_c('p',{staticClass:"more"},[_c('nuxt-link',{attrs:{"to":("/information-1-" + (_vm.$get(_vm.industry, '[0].id', '')) + "-1/")}},[_vm._v("\n        更多\n        "),_c('i',{staticClass:"el-icon-arrow-right"})])],1)],1),_vm._v(" "),_c('div',{staticClass:"exh_news_container"},[_c('div',{staticClass:"news_left"},[_c('div',{staticClass:"news_cover"},[_c('nuxt-link',{attrs:{"to":_vm.handleNewsUrl(
              _vm.$get(_vm.list, '[0].viewUrl', null),
              _vm.$get(_vm.list, '[0].newsId', null)
            ),"title":_vm.$get(_vm.list, '[0].newsName', ''),"target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$get(_vm.list, '[0].logo', '')),expression:"$get(list, '[0].logo', '')"}],attrs:{"alt":_vm.$get(_vm.list, '[0].newsName', '')}})])],1),_vm._v(" "),_c('div',{staticClass:"news_info"},[_c('p',{staticClass:"name"},[_c('i',{staticClass:"el-icon-toutiao",staticStyle:{"width":"24px"}}),_vm._v(" "),_c('span',[_c('nuxt-link',{attrs:{"to":_vm.handleNewsUrl(
                  _vm.$get(_vm.list, '[0].viewUrl', null),
                  _vm.$get(_vm.list, '[0].newsId', null)
                ),"title":_vm.$get(_vm.list, '[0].newsName', ''),"target":"_blank"}},[_vm._v("\n              "+_vm._s(_vm.$get(_vm.list, '[0].newsName', ''))+"\n            ")])],1)]),_vm._v(" "),_c('p',{staticClass:"time"},[_c('span',{staticStyle:{"margin-right":"15px"}},[_vm._v("\n            "+_vm._s(_vm._f("dateFormat")(_vm.$get(_vm.list, '[0].createTime', '')))+"\n          ")]),_vm._v(" "),_c('i',{staticClass:"el-icon-look",staticStyle:{"width":"12px"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$get(_vm.list, '[0].views', '')))])]),_vm._v(" "),_c('div',{staticClass:"content"},[_vm._v("\n          "+_vm._s(_vm._f("description")(_vm.$get(_vm.list, '[0]', {})))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"right_left"},[_c('ul',_vm._l((_vm.list.slice(1)),function(item){return _c('li',{key:item.newsId},[_c('span',{staticClass:"red_dot"}),_vm._v(" "),_c('span',{staticClass:"sub_tit"},[_c('nuxt-link',{attrs:{"to":_vm.handleNewsUrl(
                  _vm.$get(_vm.list, '[0].viewUrl', null),
                  _vm.$get(_vm.list, '[0].newsId', null)
                ),"title":_vm.$get(_vm.list, '[0].newsName', ''),"target":"_blank"}},[_vm._v("\n              "+_vm._s(item.newsName)+"\n            ")])],1),_vm._v(" "),_c('i',{staticClass:"el-icon-look",staticStyle:{"width":"12px"}}),_vm._v(" "),_c('span',{staticClass:"look"},[_vm._v(_vm._s(item.views))])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }