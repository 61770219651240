

























import Vue from 'vue';
import { $axios } from '~/util/api';
import { ResponseCode } from '~/util/constant';
import { message } from '~/util/common';

export default Vue.extend({
  data() {
    return {
      dialogShow: false,
      img: '',
      verCode: '',
    };
  },
  methods: {
    async getDate() {
      this.dialogShow = true;
      const res: any = await $axios.get(`/api/captchaApi/generate`);
      if (res.code === ResponseCode.SUCCESS) {
        this.img = res.data;
      }
    },
    async submit() {
      // let reg = /^\d+$|^\d*\.\d+$/g;
      // let reg = /^-?\d+(\.\d+)?$/;
      if (!this.verCode) {
        message.call(this, {
          delay: 3000,
          title: '请填写图形验证码',
          type: 'error',
        });
        return;
      }
      // if (!reg.test(this.verCode)) {
      //   message.call(this, {
      //     delay: 3000,
      //     title: '请输入数字',
      //     type: 'error',
      //   });
      //   return;
      // }
      const res: any = await $axios.post(
        `/api/captchaApi/verify/${this.verCode}`
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.$emit('done', this.verCode);
        this.dialogShow = false;
        this.verCode = '';
      }
    },
    resetForm() {
      this.dialogShow = false;
      this.verCode = '';
    },
  },
});
