
























































































































































import Vue from 'vue';
import { ElForm } from 'element-ui/types/form';
import { mapState } from 'vuex';
import { ResponseCode } from '~/util/constant';

enum EUserType {
  主办单位 = 1,
  展位代理公司 = 2,
  展会服务商 = 3,
}

enum EServerType {
  机票 = 1,
  酒店 = 2,
  签证 = 3,
  搭建 = 4,
  地接 = 5,
  翻译 = 6,
  其他 = 0,
}

export default Vue.extend<any, any, any, any>({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EUserType,
      rules: {
        cooperateType: [
          { required: true, message: '请选择合作类型', trigger: 'blur' },
        ],
        userType: [
          { required: true, message: '请选择企业类型', trigger: 'blur' },
        ],
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          {
            pattern: /^[^\d]*$/,
            message: '请输入正确的联系人姓名',
            trigger: 'blur',
          },
        ],
        phone: [
          { required: true, message: '请输入联系人手机', trigger: 'blur' },
          {
            pattern: /^[0-9]*$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        cooperationContent: [
          {
            required: true,
            message: '请选择需要合作的服务内容',
            trigger: 'change',
          },
        ],
      },
      formObj: {
        userType: undefined,
        companyName: '',
        contacts: '',
        phone: '',
        cooperateExhibition: [
          {
            key: 1,
            value: '',
          },
        ],
        cooperationContent: [],
      },
      // 0展位代理合作 1展位搭建合作 2签证办理合作 3机票代理合作4 展会地接合作 5展具租赁合作 6展会补贴合作
      cooperateTypeList: [
        { value: 0, label: '展位代理合作', check: false },
        { value: 1, label: '展位搭建合作', check: false },
        { value: 2, label: '签证办理合作', check: false },
        { value: 3, label: '机票代理合作', check: false },
        { value: 4, label: '展会地接合作', check: false },
        { value: 5, label: '展具租赁合作', check: false },
        { value: 6, label: '展会补贴合作', check: false },
        { value: 7, label: '观众招募合作', check: false },
        { value: 8, label: '广告投放合作', check: false },
      ],
      cooperateType: 0,
      type: 0,
      checkId: 0,
    };
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
      user: (state: any) => state.global.user,
    }),
    userType: (): Record<string, any> => {
      return Object.keys(EUserType)
        .map((k) => {
          if (!/^\d+$/.test(k)) {
            return {
              label: k,
              value: EUserType[k as keyof typeof EUserType],
            };
          }
        })
        .filter((e) => !!e);
    },
    serverType: (): Record<string, any> => {
      return Object.keys(EServerType)
        .map((k) => {
          if (!/^\d+$/.test(k)) {
            return {
              label: k,
              value: EServerType[k as keyof typeof EServerType],
            };
          }
        })
        .filter((e) => !!e);
    },
  },
  watch: {
    isLogin(val) {
      if (val) {
        this.formObj.phone = this.user.userName || '';
        this.formObj.companyName = this.user.companyName || '';
      }
    },
  },
  created() {
    if (this.isLogin) {
      this.formObj.phone = this.user.userName || '';
      this.formObj.companyName = this.user.companyName || '';
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    getType(data: any) {
      data.check = !data.check;
      this.cooperateType = data.value;
      this.checkId = data.value;
    },
    addDomain() {
      this.formObj.cooperateExhibition.push({
        value: '',
        key: Date.now(),
      });
    },
    removeDomain(data: any) {
      this.formObj.cooperateExhibition =
        this.formObj.cooperateExhibition.filter(
          (item: any) => item.key !== data.key
        );
    },
    async fecthSave() {
      const params = {
        cooperateType: this.formObj.cooperateType,
        ...this.formObj,
        cooperateExhibition:
          this.formObj.cooperateExhibition.length > 0
            ? this.formObj.cooperateExhibition
                .map((item: any) => item.value)
                .toString()
            : undefined,
        cooperationContent:
          this.formObj.cooperationContent.length > 0
            ? this.formObj.cooperationContent.toString()
            : undefined,
        promotionLink: window.location.href,
      };
      if (params.userType === EUserType.展会服务商) {
        delete params.cooperateExhibition;
      } else {
        delete params.cooperationContent;
      }
      const res = await (<any>(
        this.$axios.post(`/api/companyCooperate/save`, params)
      ));
      if (res.code === ResponseCode.SUCCESS) {
        this.handleClose();
      }
    },
    resetForm() {
      const form = this.$refs.joinForm as ElForm;
      form.resetFields();
    },
    submit() {
      if (!this.isLogin) {
        return this.$login.show();
      }
      const form = this.$refs.joinForm as ElForm;
      form.validate((valid) => {
        if (valid) {
          this.fecthSave();
        }
      });
    },
  },
});
