


























import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '',
      dialogImg: '',
    };
  },
  methods: {
    handleShowWechat() {
      this.dialogVisible = true;
      this.dialogTitle = <string>this.$t('footer.scan_follow');
      this.dialogImg = require('~/assets/images/juzhan-gh.jpg');
    },
    handleShowMini() {
      this.dialogVisible = true;
      this.dialogTitle = <string>this.$t('footer.scan_openApplet');
      this.dialogImg = require('~/assets/images/juzhan-mini.jpg');
    },
  },
});
