






























































import Vue from 'vue';
import _ from 'lodash';
// @ts-ignore
import BMap from 'vue-baidu-map/components/map/Map.vue';
// @ts-ignore
import BInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow.vue';
// @ts-ignore
import BMarker from 'vue-baidu-map/components/overlays/Marker.vue';
// @ts-ignore
import BNavigation from 'vue-baidu-map/components/controls/Navigation.vue';
import { baiduKey } from '~/util/common';

import 'leaflet/dist/leaflet.css';

if (process.client) {
  // leaflet
  const { LMap, LTileLayer, LMarker, LPopup } = require('vue2-leaflet');
  Vue.component('l-map', LMap);
  Vue.component('l-tile-layer', LTileLayer);
  Vue.component('l-marker', LMarker);
  Vue.component('l-popup', LPopup);
}

export default Vue.extend({
  name: 'Map',
  components: {
    BMap,
    BMarker,
    BInfoWindow,
    BNavigation,
  },
  filters: {
    centerFormat(item: Record<string, any>) {
      const lng = _.get(item, 'longitude', '');
      const lat = _.get(item, 'latitude', '');
      const pavilionName = _.get(item, 'pavilionName', '');
      if (lng && lat) {
        return { lat, lng };
      } else {
        return pavilionName;
      }
    },
  },
  props: {
    detail: {
      type: Object,
      default: null,
    },
    center: {
      type: Array,
    },
    attribution: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 295,
    },
    width: {
      type: Number,
      default: 795,
    },
  },
  data() {
    return {
      baiduKey,
      BMap: null,
      map: null,
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 16,
      showInfoWindow: true,
      position: {},
    };
  },
  methods: {
    // 百度地图加载完成
    baiduMapReady({ BMap, map }: Record<string, any>) {
      this.BMap = BMap;
      this.map = map;
      setTimeout(() => {
        this.position = map.getCenter();
      }, 800);
    },
    infoWindowToggle() {
      this.showInfoWindow = !this.showInfoWindow;
    },
  },
});
