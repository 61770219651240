





























import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';

// 签证提交订单页-头部导航
export default Vue.extend({
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      keyword: '',
      credit: 0,
    };
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  methods: {},
});
