



























import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend<any, any, any>({
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: [Number, String],
      default: '',
    },
  } as any,
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  methods: {
    handleMenu(e: any) {
      if (e.title === '酒店') {
        window.open(e.path, '_self');
      } else {
        this.$router.push({
          path: e.path,
        });
      }
    },
    toPath(url: string) {
      window.open(url, '_blank');
    },
    handleLogin() {
      this.$login.show();
    },
    handleSearch: _.debounce(
      function () {
        // @ts-ignore
        const { keyword } = this;
        // if (!isLogin) {
        //   return handleLogin();
        // }
        if (keyword) {
          window.open(`/exhibition/?keyword=${keyword}`, '_blank');
        }
      },
      3000,
      { leading: true }
    ),
    handleFold() {
      this.isCollapse = !this.isCollapse;

      const w = this.isCollapse ? 63 : 150;
      this.$emit('on-change', w);
    },
  },
});
