//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  props: {
    title: String,
    h1: {
      type: Boolean,
      default: false,
    },
    h2: {
      type: Boolean,
      default: false,
    },
  },
});
