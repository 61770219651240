












































































































import Vue from 'vue';
// @ts-ignore
import currency from 'currency.js';

export default Vue.extend({
  name: 'HotelItem',
  filters: {
    priceFormat(value: number) {
      return currency(value).divide(100).dollars();
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      EHotelStar: {
        豪华型: 5,
        五星级: 5,
        高档型: 4,
        四星级: 4,
        舒适型: 3,
        三星级: 3,
        经济型: 2,
        其他型: 2,
      },
    };
  },
  methods: {
    getSatisfaction(score: string) {
      const num = parseFloat(score);
      if (num >= 4.5) {
        return '非常好';
      } else if (num >= 4) {
        return '优秀';
      } else {
        return '满意';
      }
    },
    startFilter(value: string) {
      // @ts-ignore
      return this.EHotelStar[value];
    },
  },
});
